/* slider.css */

.logo-slider-container {
    width: 95%; /* Adjust the width of the slider container as needed */
    margin: auto;
  }
  
  .logo-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px; /* Set a fixed height for each logo item */
  }
  
  .logo-item img {
    max-width: 89%;
    max-height: 97%;
    padding: 10px;
    object-fit: contain;
  }
  .ap-bannerItem1 img{max-width: 100% !important}
.ap-banner-slider1 .slick-dots{bottom: 25px;}
.ap-banner-slider1 .slick-dots li button{width: 16px;height: 16px;background-color: rgb(255, 255, 255);border-radius: 50%;}
.ap-banner-slider1 .slick-dots li.slick-active button{background-color: var(--primary-blue);}
.ap-banner-slider1 .slick-dots li button:before{display: none;}

.ap-banner-cnt1{max-width: 850px;width: 100%;left: 12%;top: 50%;transform: translateY(-50%);}
.ap-banner-cnt1 p, .ap-banner-cnt1 h2, .ap-banner-cnt1 h3{margin: 0;line-height: normal;}
.ap-banner-cnt1 p{font-size: 35px;line-height: 1.2;}
.ap-banner-cnt1 h2{font-size: 80px;margin: 5px 0;}
.ap-banner-cnt1 h3{font-size: 55px;line-height: 1.3;}
.ap-banner-cnt1 .ap-joinBtn1{font-size: 35px;border: 4px solid var(--primary-black);padding: 5px 15px;margin-top: 35px;}

.ap-mobBanner1{display: none !important;}
.tt-title-01{
font-size: 30px;
text-transform: capitalize;
/* margin-top: -290px; */
color: beige;
font-weight: 500;
}
.slide-content{
    margin-top: -307px;
    position: absolute;
    margin-left: 450px;
}
.tt-title-02{
    font-size: 70px;
    text-transform: capitalize;
    line-height: 1;
    color: beige;
    font-weight: 500; 
}
.slick-arrow {
    display: none;
  }


@media screen and (max-width:1600px) {
    .ap-banner-cnt1 p{font-size:30px;}
    .ap-banner-cnt1 h2{font-size:65px;}
    .ap-banner-cnt1 h3{font-size:46px;}
    .ap-banner-cnt1{max-width:695px;}
    .ap-banner-cnt1 .ap-joinBtn1{font-size:28px;border-width:3px;}
    .ap-banner-cnt1{left: 10%;}
   


}
  
@media screen and (max-width:1540px) {
  
    .ap-banner-cnt1 p{font-size:25px;}
    .ap-banner-cnt1 h2{font-size:55px;}
    .ap-banner-cnt1 h3{font-size:40px;}
    .ap-banner-cnt1{max-width:600px;}
    .ap-banner-cnt1 .ap-joinBtn1{font-size:22px;}
    .ap-banner-slider1 .slick-dots li button{width:12px;height:12px;}

}
@media screen and (max-width:1500px) {
   

}
@media screen and (max-width:1400px) {
    
    .ap-banner-slider1 .slick-dots li button{width: 12px;height: 12px;}
    .ap-banner-slider1 .slick-dots{left: -29%;bottom: -34px;}


}

@media screen and (max-width:1300px) {


}
  
@media screen and (max-width:1100px) {
  
}
  
@media screen and (max-width:992px) {
    .tt-title-01{
        text-align: center;
       display: flex;
        margin-top: 190px;
       
        font-size: 21px;
    }
    .tt-title-02{
        font-size: 15px;
        font-weight: 400;
    }
   
}
.bene{
    margin-top: 200px;
}
@media screen and (max-width:550px) {
    .bene{
        margin-top: 66px;
    }
    .ap-mobBanner1{display: block !important;}
    .ap-deskBanner1{
        margin-top: 20px;
        height: 200px;
        /* border-radius: 15px; */
        display: block !important;}
    .ap-banner-cnt1 p{font-size:16px;}
    .ap-banner-cnt1 h2{font-size:28px;}
    .ap-banner-cnt1 h3{font-size:18px;}
    .ap-banner-cnt1 .ap-joinBtn1{font-size:14px;border-width:2px;margin-top: 14px;}
    .ap-banner-cnt1{left: 4%;top: 20%;max-width: 92%;}
    .tt-title-01{
        text-align: center;
       display: flex;
        margin-top: 190px;
       
        font-size: 21px;
    }
    .tt-title-02{
        font-size: 15px;
        font-weight: 400;
    }
}

  